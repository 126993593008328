import React, { useEffect, useState } from 'react'

const Fold = () => {
  const [cssLoaded, setCssLoaded] = useState(false)
  useEffect(() => {
    const lazyLoadedStylesheets = document.querySelectorAll(
      'link[data-lazy="stylesheet"]',
    )

    if (!lazyLoadedStylesheets.length) {
      setCssLoaded(true)
      return
    }

    Promise.all(
      Array.from(lazyLoadedStylesheets).map(
        (link) =>
          new Promise((resolve, reject) => {
            if (link.sheet) {
              resolve()
            } else {
              link.addEventListener('load', () => {
                resolve()
              })
              link.addEventListener('error', () => {
                reject()
              })
            }
            link.media = 'all'
            link.rel = 'stylesheet'
          }),
      ),
    )
      .catch(() => {})
      .finally(() => {
        setCssLoaded(true)
      })

    const fallbackTimeout = setTimeout(() => {
      setCssLoaded(true)
    }, 5000)

    return () => clearTimeout(fallbackTimeout)
  }, [])

  if (cssLoaded) return null
  return <div className="__fold__ h-screen" />
}

export default Fold
