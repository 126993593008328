import balanceText from 'balance-text'
import React, { useLayoutEffect, useRef } from 'react'
import { isSSR } from 'js/isSSR'

type Props = {
  children: React.ReactComponentElement<any>
}
const ActualBalanceText = ({ children }: Props) => {
  const ref = useRef<HTMLSpanElement>(null)

  // Ensure we always throw away the span and render a new one
  // if something changes, or else we could end up with duplicate
  // content in the span.
  const key = Date.now()

  useLayoutEffect(() => {
    balanceText(ref.current, { watch: true })
    // FIXME: dont ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  if (React.Children.count(children) === 1) {
    return React.cloneElement(React.Children.only(children), { ref, key })
  }

  return (
    <span ref={ref} key={key}>
      {children}
    </span>
  )
}

const BalanceText = ({ children }: Props) => {
  if (isSSR) {
    return children
  }

  return <ActualBalanceText>{children}</ActualBalanceText>
}

export default BalanceText
