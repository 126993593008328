import React from 'react'
import Overlay from '../Overlay/Overlay'
import Login from './Login'
import { useNavigate } from 'react-router-dom'

type Props = {
  goToAccount: boolean
  onClose: () => void
  showSignupLink?: boolean
}

const LoginOverlay = ({
  onClose,
  goToAccount = true,
  showSignupLink = true,
}: Props) => {
  const navigate = useNavigate()

  const handleSuccess = () => {
    if (goToAccount) navigate('/account')
    onClose()
  }

  return (
    <Overlay onOutsideClick={onClose}>
      <Login
        onSuccess={handleSuccess}
        showSignupLink={showSignupLink}
        onClose={onClose}
      />
    </Overlay>
  )
}

export default LoginOverlay
