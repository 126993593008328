import React from 'react'
import classNames from 'classnames'
import Spinner from '../Spinner/Spinner'

type Props = {
  use?: any
  outline?: boolean
  glow?: boolean
  block?: boolean
  color?: 'green' | 'premium' | 'black' | 'red' | 'white' | 'facebook'
  big?: boolean
  loading?: boolean
  children: JSX.Element | string
  /** A tailwind class for height, e.g. h-40 */
  height?: string
  /** Any additional classes */
  className?: string
  disabled?: boolean
  nudge?: boolean
  shadow?: string | false
  truncate?: boolean
  href?: string
  download?: string
  /** When Link component is used */
  to?: string
  onClick?: (event: React.SyntheticEvent) => void
  autoFocus?: boolean
  type?: string
}

export default function Button({
  use: Component = 'button',
  outline = false,
  glow = !outline,
  color = 'green',
  block = false,
  big = false,
  loading = false,
  children,
  height,
  nudge = true,
  shadow = false,
  truncate = true,
  className,
  ...props
}: Props) {
  return (
    <Component
      className={classNames(
        className,
        'relative max-w-full no-underline',
        {
          'inline-block': !block,
          'block w-full': block,
        },
        'relative',
        'rounded-full',
        'items-center',
        'justify-center',
        'px-32',
        { 'transform hover:-translate-y-1': nudge },
        'transition-slow transition',
        'type-upper-fat',
        'cursor-pointer',
        height || (big ? 'h-60' : 'h-40'),
        shadow,
        {
          'whitespace-nowrap': truncate,
          'inline-flex': !block,
          flex: block,
          'text-white bg-gradient-green': !outline && color === 'green',
          'border-2 border-green text-green': outline && color === 'green',
          'text-white bg-gradient-premium': !outline && color === 'premium',
          'border-2 border-red text-red': outline && color === 'premium',
          'bg-black text-white': !outline && color === 'black',
          'border-2 border-black text-black': outline && color === 'black',
          'bg-secondary-red text-white': !outline && color === 'red',
          'border-2 border-secondary-red text-secondary-red':
            outline && color === 'red',
          'bg-white text-grey-darkest': !outline && color === 'white',
          'border-2 border-white text-white': outline && color === 'white',
          'opacity-40': props.disabled,
          'shadow-greenish': !shadow && glow && color === 'green',
          'shadow-premium': !shadow && glow && color === 'premium',
          'bg-[#3f65bb] text-white': !outline && color === 'facebook',
        },
      )}
      {...props}
    >
      <span
        className={classNames('relative transition', {
          'opacity-0': loading,
          truncate: truncate,
        })}
      >
        <span className="relative" style={{ top: 1 }}>
          {children}
        </span>
      </span>
      {loading && <Spinner className="absolute" width="26" height="26" />}
    </Component>
  )
}
