import React, { useState } from 'react'
import cn from 'classnames'
import loadable from '@loadable/component'
import LifesumLogoCircle from 'media/logo-short.svg?svgr-webpack'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import useLocale from '../../hooks/useLocale'
import { FormattedMessage } from 'react-intl'
import {
  logOut,
  selectIsLoggedIn,
  selectIsPremium,
} from '../../store/userSlice'
import { trackStartRegistration } from '../../analytics'
import { selectPricingConfig } from '../../store/pricingSlice'
import { localizeLink } from '../../utils'
import LanguageSwitcher from './LanguageSwitcher'
import useBreakpoints from '../../hooks/useBreakpoints'
import useScroll from 'js/hooks/useScroll'
import Logo from 'js/components/Logo'
import { useShowMenu } from 'js/hooks/useShowMenu'

const LoginOverlay = loadable(() => import('../Login/LoginOverlay'))

export const linkClasses =
  'type-14-demibold md:type-14-demibold-caps xl:type-17-demibold-caps text-inherit mx-6 lg:mx-12 py-2 border-b-2 border-transparent hover:border-Brand transition'

type WrapperProps = {
  children: React.ReactNode
  sticky: boolean
  className?: string
  inverted: boolean
  isStuck: boolean
  fixedPosition: boolean
}

const Wrapper = ({
  children,
  sticky,
  isStuck,
  fixedPosition,
  inverted,
  className,
}: WrapperProps) => (
  <header
    role="banner"
    className={cn(
      'pb-6 pt-12 md:pb-12 lg:py-20 xl:py-30',
      'z-30 transition',
      sticky && fixedPosition ? 'fixed' : 'sticky',
      sticky && 'left-0 right-0 top-0',
      isStuck && 'bg-white shadow-elevated',
      inverted && !isStuck ? 'text-TypeConstant' : 'text-Type',
      className,
    )}
  >
    <div className="mx-auto max-w-1680 px-16 md:px-24 xl:px-64 xxl:px-128">
      {children}
    </div>
  </header>
)

type LogoProps = {
  inverted: boolean
  className?: string
  isStuck: boolean
  logoOnly: boolean
}

const LogoLink = ({ inverted, isStuck, logoOnly, className }: LogoProps) => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isPremium = useSelector(selectIsPremium)
  const { language } = useLocale()
  const { MD } = useBreakpoints()
  const showMenu = useShowMenu()
  const useCircleLogo = !MD && isLoggedIn && !isPremium

  const homeLink = `/${language !== 'en' ? language : ''}`

  return (
    <Link
      data-uid="home_header_logo"
      data-testid="header-logo"
      to={showMenu ? homeLink : '/account'}
      className={cn(
        className,
        'xl:-mt-4',
        !logoOnly
          ? 'mr-auto flex items-center lg:mr-24 lg:block xl:mr-40'
          : 'block',
      )}
    >
      <span className="sr-only">Lifesum.com</span>
      {useCircleLogo ? (
        <LifesumLogoCircle
          className={cn('c-logo', {
            'c-logo--short': useCircleLogo,
            'text-TypeConstant': inverted && !isStuck,
          })}
        />
      ) : (
        <Logo
          className={cn({
            'text-TypeConstant': inverted && !isStuck,
          })}
        />
      )}
    </Link>
  )
}

type ListItemProps = {
  children: React.ReactNode
  className?: string
}

const ListItem = ({ children, className }: ListItemProps) => (
  <li className={className}>{children}</li>
)

type SignUpOrUpgradeLinkProps = {
  inverted: boolean
  isStuck: boolean
  className?: string
}

const SignUpOrUpgradeLink = ({
  className,
  inverted,
  isStuck,
}: SignUpOrUpgradeLinkProps) => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isPremium = useSelector(selectIsPremium)
  const pricingConfig = useSelector(selectPricingConfig())
  const { language } = useLocale()

  if (isPremium) return null

  return (
    <Link
      className={cn(
        'type-14-demibold xl:type-17-demibold',
        'block rounded-full transition',
        'border-2 bg-transparent',
        'px-12 py-4 lg:ml-12 xl:px-20 xl:pt-6',
        'max-w-golden-ratio truncate text-center lg:max-w-none',
        'lg:relative',
        className,
        inverted && !isStuck
          ? 'border-TypeConstant text-TypeConstant hover:bg-TypeConstant hover:text-Type'
          : 'border-Brand text-BrandDark hover:bg-Brand hover:text-TypeConstant',
      )}
      style={{ bottom: 2 }}
      data-uid="signup_header_button"
      to={localizeLink(isLoggedIn ? '/upgrade/' : '/signup/', language)}
      onClick={() => {
        if (!isLoggedIn) {
          trackStartRegistration()
        }
      }}
    >
      {isLoggedIn ? (
        <FormattedMessage
          id="Account.upgradeToPremium"
          defaultMessage="Upgrade to Premium"
        />
      ) : (
        <>
          {pricingConfig?.trialDuration > 0 ? (
            <FormattedMessage
              id="trial_tryForFree"
              defaultMessage="Try for free"
            />
          ) : (
            <FormattedMessage
              id="Header.menuBar.signUp"
              defaultMessage="Sign up"
            />
          )}
        </>
      )}
    </Link>
  )
}

const AccountLink = () => {
  const { language } = useLocale()

  return (
    <Link
      data-uid="account_header_button"
      to={localizeLink('/account/', language)}
      className={linkClasses}
    >
      <FormattedMessage id="Header.menuBar.account" defaultMessage="Account" />
    </Link>
  )
}

const LogOutButton = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <button
      data-uid="logout_header_button"
      onClick={(e) => {
        e.preventDefault()
        dispatch(logOut())
        navigate('/')
      }}
      style={{ maxWidth: 75 }}
      className={cn(linkClasses, 'truncate sm:max-w-none')}
    >
      <FormattedMessage id="Header.menuBar.logOut" defaultMessage="Log out" />
    </button>
  )
}

type Props = {
  sticky?: boolean
  logoOnly?: boolean
  inverted?: boolean
  fixedPosition?: boolean
  translated?: boolean
}

export default function Header({
  sticky = true,
  inverted = true,
  logoOnly = false,
  fixedPosition = false,
  translated = true,
}: Props) {
  const [showLogin, setShowLogin] = useState(false)
  const showMenu = useShowMenu()
  const { language } = useLocale()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const { y: yScroll } = useScroll()
  const isStuck = sticky && yScroll > 0

  if (logoOnly)
    return (
      <Wrapper
        className="text-center"
        fixedPosition={fixedPosition}
        inverted={inverted}
        isStuck={isStuck}
        sticky={sticky}
      >
        <LogoLink isStuck={isStuck} inverted={inverted} logoOnly={logoOnly} />
      </Wrapper>
    )

  return (
    <>
      <Wrapper
        fixedPosition={fixedPosition}
        inverted={inverted}
        isStuck={isStuck}
        sticky={sticky}
      >
        <div className="items-center lg:flex">
          <div className="flex items-center lg:block">
            <LogoLink
              logoOnly={logoOnly}
              inverted={inverted}
              isStuck={isStuck}
            />
            {showMenu && (
              <SignUpOrUpgradeLink
                inverted={inverted}
                className="lg:hidden"
                isStuck={isStuck}
              />
            )}
            {translated ? (
              <div className="-mr-6 ml-4 lg:hidden">
                <LanguageSwitcher inverted={inverted} isStuck={isStuck} />
              </div>
            ) : null}
          </div>
          <nav className="grow">
            <ul
              className={cn(
                'flex items-center',
                inverted && !isStuck ? 'text-TypeConstant' : 'text-Type',
                '-mx-6 mt-16 md:mt-24 lg:mx-0 lg:mt-0',
              )}
            >
              {showMenu && (
                <ListItem>
                  <Link
                    to={localizeLink('/features/', language)}
                    className={linkClasses}
                  >
                    <FormattedMessage
                      id="Header.menuBar.features"
                      defaultMessage="Features"
                    />
                  </Link>
                </ListItem>
              )}
              <ListItem className="mr-auto">
                <Link to="/nutrition-explained/" className={linkClasses}>
                  <FormattedMessage
                    id="Header.menuBar.articles"
                    defaultMessage="Articles"
                  />
                </Link>
              </ListItem>
              {translated ? (
                <ListItem className="hidden lg:block">
                  <LanguageSwitcher inverted={inverted} isStuck={isStuck} />
                </ListItem>
              ) : null}
              {isLoggedIn ? (
                <>
                  <ListItem>
                    <AccountLink />
                  </ListItem>
                  <ListItem>
                    <LogOutButton />
                  </ListItem>
                </>
              ) : (
                !isLoggedIn && (
                  <ListItem>
                    <button
                      type="button"
                      data-uid="login_header_button"
                      onClick={() => setShowLogin(true)}
                      className={linkClasses}
                    >
                      <FormattedMessage
                        id="Header.menuBar.logIn"
                        defaultMessage="Log in"
                      />
                    </button>
                  </ListItem>
                )
              )}
              {showMenu && (
                <li className="hidden lg:block">
                  <SignUpOrUpgradeLink isStuck={isStuck} inverted={inverted} />
                </li>
              )}
            </ul>
          </nav>
        </div>
      </Wrapper>
      {showLogin && <LoginOverlay onClose={() => setShowLogin(false)} />}
    </>
  )
}
