import { useEffect, useState } from 'react'
import { throttle } from 'throttle-debounce'
import { isSSR } from 'js/isSSR'

export default function useScroll({
  callback = () => {},
  condition = true,
} = {}) {
  const [scrollPosition, setScrollPosition] = useState({
    x: isSSR ? 0 : window.scrollX,
    y: isSSR ? 0 : window.scrollY,
  })

  useEffect(() => {
    const throttledSetter = throttle(100, () =>
      setScrollPosition({
        x: window.scrollX,
        y: window.scrollY,
      }),
    )

    if (condition) {
      window.addEventListener('scroll', throttledSetter)
      return () => window.removeEventListener('scroll', throttledSetter)
    }
  }, [condition])

  useEffect(callback, [scrollPosition, callback])

  return scrollPosition
}
