// google numbers source
// https://play.google.com/console/u/0/developers/6766744678828405313/app/4974549827859657507/user-feedback/ratings
export const googleReviewCount = 333643
export const googleRating = 4.4
// apple numbers source
// https://appstoreconnect.apple.com/apps/286906691/appstore/activity/ios/ratingsResponses?m=
export const appleReviewCount = 587314
export const appleRating = 4.6
export const reviewCount = googleReviewCount + appleReviewCount
export const averageRating =
  Math.round(
    ((googleReviewCount * googleRating + appleReviewCount * appleRating) /
      reviewCount) *
      10,
  ) / 10
export const usersInMillions = 55
export const modified = '2023-08-09'
