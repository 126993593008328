import React from 'react'
import { Link as HeadLink, Meta, Title } from 'react-head'
import { languages } from '../../locale'
import { useIntl } from 'react-intl'
import useLocale from 'js/hooks/useLocale'

export function HeadTags() {
  const { formatMessage } = useIntl()
  const { language } = useLocale()

  return (
    <>
      <Title>
        {formatMessage({
          id: 'homepage_title',
          defaultMessage: 'Lifesum - Healthy eating. Simplified.',
        })}
      </Title>
      <Meta
        name="description"
        content={formatMessage({
          id: 'homepage_description',
          defaultMessage:
            'Lifesum helps you improve your well-being with insights regarding food and nutrition while creating sustainable habits – start your health journey today.',
        })}
      />
      <HeadLink
        rel="canonical"
        href={`https://lifesum.com/${language !== 'en' ? `${language}/` : ''}`}
      />
      <HeadLink
        rel="alternate"
        href="https://lifesum.com/"
        // @ts-ignore
        hreflang="x-default"
      />
      {languages.map((locale) => (
        <HeadLink
          rel="alternate"
          href={
            locale === 'en'
              ? `https://lifesum.com/`
              : `https://lifesum.com/${locale}/`
          }
          // @ts-ignore
          hreflang={locale}
          key={locale}
        />
      ))}
    </>
  )
}
