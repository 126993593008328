import { defineMessages } from 'react-intl'

export default defineMessages({
  availableOnGooglePlay: {
    id: 'AppButtons.alsoAvailable.googlePlay',
    defaultMessage: 'Also available on',
  },
  availableOnAppStore: {
    id: 'AppButtons.alsoAvailable.appStore',
    defaultMessage: 'Also available on the',
  },
})
