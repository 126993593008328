import React, { useState, useEffect } from 'react'
import { iOS, android, appButtonUrls } from '../../device'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import useBreakpoints from '../../hooks/useBreakpoints'
import useLocale from '../../hooks/useLocale'
import { useSelector } from 'react-redux'
import { selectIsAuthenticated } from '../../store/userSlice'
import enGoogle from './images/google/en.svg'
import enApple from './images/apple/en.svg'
import Button from 'js/components/Button/Button'
import { trackAppStoreButtonClick } from 'js/analytics'

type AppButtonProps = {
  className?: string
  type: 'apple' | 'google'
  url?: string
  plain?: boolean
  inTopBar?: boolean
  color?: string
}

const AppButton = ({
  className,
  type,
  url,
  plain,
  inTopBar,
  color,
}: AppButtonProps) => {
  const [image, setImage] = useState(null)
  const { language } = useLocale()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  useEffect(() => {
    let aborted = false
    if (plain) return

    import(
      /* webpackChunkName: "[request]" */ `./images/${type}/${language.toLowerCase()}.svg`
    )
      .then(({ default: image }) => {
        if (aborted) {
          return
        }
        setImage(image)
      })
      .catch(() => {
        if (aborted) {
          return
        }
        type === 'apple'
          ? setImage(enApple)
          : type === 'google'
          ? setImage(enGoogle)
          : null
      })

    return () => {
      aborted = true
    }
  }, [language, type, plain])
  const { LG, XL } = useBreakpoints()

  let height = 40
  if (LG) height = 45
  if (XL) height = 60

  let alt
  if (type === 'apple') alt = 'Download on the App Store'
  if (type === 'google') alt = 'Get it on Google Play'

  const handleClick = () => {
    if (isAuthenticated) return

    trackAppStoreButtonClick({ type })
  }

  if (inTopBar) {
    return (
      <a
        href={url}
        className="border-color-green ml-auto rounded-4 border-2 px-16 py-2 text-center font-demibold text-green"
        onClick={handleClick}
      >
        <FormattedMessage id="install_now" />
      </a>
    )
  }

  if (plain) {
    return (
      <Button
        use="a"
        href={url}
        onClick={handleClick}
        color={color === 'orange' ? 'premium' : 'green'}
      >
        <FormattedMessage id="install_now" />
      </Button>
    )
  }

  if (!image) return null

  return (
    <a
      className={classnames(
        'hover:-translate-y-3px mb-4 inline-block transition md:mb-12',
        className,
      )}
      href={url}
      onClick={handleClick}
    >
      <img
        className="w-auto align-middle"
        style={{ height }}
        src={image}
        alt={alt}
      />
    </a>
  )
}

type Props = {
  centered?: boolean
  onlyOne?: boolean
  onDark?: boolean
  appStoreUrl?: string
  googlePlayUrl?: string
  plain?: boolean
  inTopBar?: boolean
  buttonColor?: string
}

const AppButtons = ({
  centered,
  onlyOne = false,
  onDark,
  appStoreUrl = appButtonUrls.apple,
  googlePlayUrl = appButtonUrls.google,
  plain,
  inTopBar,
  buttonColor,
}: Props) => {
  if (!appStoreUrl) {
    appStoreUrl = appButtonUrls.apple
  }
  if (!googlePlayUrl) {
    googlePlayUrl = appButtonUrls.google
  }

  return (
    <div
      className={classnames(
        'relative z-10',
        {
          'text-center': centered,
        },
        onDark ? 'text-white' : 'text-grey-darkest',
      )}
      data-uid="AppButtons"
    >
      {iOS && (
        <>
          <AppButton
            type="apple"
            url={appStoreUrl}
            plain={plain}
            inTopBar={inTopBar}
            color={buttonColor}
          />
          {!onlyOne && (
            <p className="mt-12 type-17">
              <FormattedMessage {...messages.availableOnGooglePlay} />{' '}
              <a
                className="text-inherit underline transition hover:text-blue"
                href={googlePlayUrl}
              >
                Google&nbsp;Play
              </a>
              .
            </p>
          )}
        </>
      )}
      {android && (
        <>
          <AppButton
            type="google"
            url={googlePlayUrl}
            plain={plain}
            inTopBar={inTopBar}
            color={buttonColor}
          />
          {!onlyOne && (
            <p className="mt-12 type-17">
              <FormattedMessage {...messages.availableOnAppStore} />{' '}
              <a
                className="text-inherit underline transition hover:text-blue"
                href={appStoreUrl}
              >
                App&nbsp;Store
              </a>
              .
            </p>
          )}
        </>
      )}
      {!iOS && !android && !onlyOne && (
        <div className="relative -mx-4 lg:-mx-12">
          <AppButton type="apple" url={appStoreUrl} className="mx-4 lg:mx-12" />
          <AppButton
            type="google"
            url={googlePlayUrl}
            className="mx-4 lg:mx-12"
          />
        </div>
      )}

      {!iOS && !android && onlyOne && <AppButton type="apple" />}
    </div>
  )
}

export default AppButtons
