import { CmsSourceUrl } from 'typings'

const appIcon: CmsSourceUrl =
  'https://www.datocms-assets.com/23496/1671463001-256.png'
const homeHeroImage: CmsSourceUrl =
  'https://www.datocms-assets.com/23496/1687180259-no-rights-yet-homepage-image.png'

const homeImages = {
  testimonialMarianneSquare: {
    src: 'https://www.datocms-assets.com/23496/1687859612-testimonial-marianne.png?auto=format&crop=focalpoint&fit=crop&fp-x=0.51&fp-y=0.21',
    width: 2000,
    height: 2000,
    bgColor: '#c49879',
  },
  testimonialBjarneSquare: {
    src: 'https://www.datocms-assets.com/23496/1688478588-testominal-bjarne.jpeg?auto=format&crop=focalpoint&fit=crop&fp-x=0.49&fp-y=0.34',
    width: 2000,
    height: 2000,
    bgColor: '#328baa',
  },
  testimonialHeleneSquare: {
    src: 'https://www.datocms-assets.com/23496/1688478531-testominal-helene.jpeg?auto=format&crop=focalpoint&fit=crop&fp-x=0.54&fp-y=0.28',
    width: 2000,
    height: 2000,
    bgColor: '#35a870',
  },
  featuresLifescoreLifestyle: {
    src: 'https://www.datocms-assets.com/23496/1687424144-feature-1.png?auto=format&fit=crop',
    width: 3000,
    height: 3564,
    bgColor: '#aa522f',
  },
  featuresLifescoreApp: {
    src: 'https://www.datocms-assets.com/23496/1692187964-life-score-card.png?auto=format&fit=crop',
    width: 3000,
    height: 3670,
    bgColor: '#00e307',
  },
  featuresMacrosLifestyle: {
    src: 'https://www.datocms-assets.com/23496/1687856714-macros-image-1.png?auto=format&fit=crop',
    width: 3000,
    height: 1925,
    bgColor: '#b26e51',
  },
  featuresMacrosApp: {
    src: 'https://www.datocms-assets.com/23496/1687859661-macros-image-2.png?auto=format&fit=crop',
    width: 3000,
    height: 1682,
    bgColor: '#03e42a',
  },
  featuresMealPlansLifestyle: {
    src: 'https://www.datocms-assets.com/23496/1687859651-mealplans-image-1.png?auto=format&fit=crop',
    width: 3000,
    height: 3909,
    bgColor: '#d45f23',
  },
  featuresMealPlansApp: {
    src: 'https://www.datocms-assets.com/23496/1687859646-mealplans-image-2.png?auto=format&fit=crop',
    width: 3000,
    height: 1209,
    bgColor: '#e2982b',
  },
  featuresMealPlansApp1: {
    src: 'https://www.datocms-assets.com/23496/1687944567-keto-burn.png?auto=format&fit=crop',
    width: 3000,
    height: 3833,
    bgColor: '#f5af2c',
  },
  featuresMealPlansApp2: {
    src: 'https://www.datocms-assets.com/23496/1687944556-hormonal.png?auto=format&fit=crop',
    width: 3000,
    height: 3831,
    bgColor: '#e0912b',
  },
  featuresMealPlansApp3: {
    src: 'https://www.datocms-assets.com/23496/1687943965-16-8-morning-fasting.png?auto=format&fit=crop',
    width: 3000,
    height: 3829,
    bgColor: '#e39925',
  },
  featuresTrackingLifestyle: {
    src: 'https://www.datocms-assets.com/23496/1687859627-tracker-image.png?auto=format&fit=crop',
    width: 3000,
    height: 2707,
    bgColor: '#c66e3a',
  },
  featuresTrackingLifestylePortrait: {
    src: 'https://www.datocms-assets.com/23496/1687944576-tracker-image-vertical.png?auto=format&fit=crop',
    width: 3000,
    height: 3576,
    bgColor: '#af4c33',
  },
  featuresTrackingApp: {
    src: 'https://www.datocms-assets.com/23496/1687859631-tracker-card.png?auto=format&fit=crop',
    width: 3000,
    height: 1027,
    bgColor: '#1653c3',
  },
  phoneScreen: {
    src: 'https://www.datocms-assets.com/23496/1688036342-phone-1.png?auto=format&fit=crop',
    width: 3000,
    height: 4515,
    bgColor: '#b16e3b',
  },
  testimonialMarianne: {
    src: 'https://www.datocms-assets.com/23496/1687859612-testimonial-marianne.png?auto=format&fit=crop',
    width: 3000,
    height: 4013,
    bgColor: '#c49879',
  },
  testimonialBjarne: {
    src: 'https://www.datocms-assets.com/23496/1688478588-testominal-bjarne.jpeg?auto=format&fit=crop',
    width: 3000,
    height: 4027,
    bgColor: '#328baa',
  },
  testimonialHelene: {
    src: 'https://www.datocms-assets.com/23496/1688478531-testominal-helene.jpeg?auto=format&fit=crop',
    width: 3000,
    height: 4020,
    bgColor: '#35a870',
  },
  featuresBarcodeScannerLifestyle: {
    src: 'https://www.datocms-assets.com/23496/1687977472-barcode-scanner-3.png?auto=format&fit=crop',
    width: 3000,
    height: 1976,
    bgColor: '#cc7322',
  },
  featuresBarcodeScannerApp: {
    src: 'https://www.datocms-assets.com/23496/1687978241-barcode-scanner-app.png?auto=format&fit=crop',
    width: 3000,
    height: 3899,
    bgColor: '#de5b20',
  },
  heroImage: {
    src: 'https://www.datocms-assets.com/23496/1687975482-hero-image.png?auto=format&fit=crop',
    width: 3000,
    height: 1563,
    bgColor: '#b48d4e',
  },
  heroImageMobile: {
    src: 'https://www.datocms-assets.com/23496/1687975461-hero-image-mobile.png?auto=format&fit=crop',
    width: 3000,
    height: 5138,
    bgColor: '#48759b',
  },
  missionMobile: {
    src: 'https://www.datocms-assets.com/23496/1688065848-mixed-fruit-veggie-1.png?auto=format&fit=crop',
    width: 2966,
    height: 2000,
  },
}

export { appIcon, homeHeroImage, homeImages }
