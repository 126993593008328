import React from 'react'
export type FeatureImageType = {
  src: string
  width: number
  height: number
  bgColor?: string
}

const imageBreakpoints = [350, 800, 1600]
const getQueryParameterSeparator = (src: string) =>
  src?.includes('?') ? '&' : '?'
const getSrcSet = (src: string) =>
  imageBreakpoints
    .map(
      (breakpoint) =>
        `${src}${getQueryParameterSeparator(
          src,
        )}w=${breakpoint} ${breakpoint}w`,
    )
    .join(', ')

const getSquareSrcSet = (src: string) =>
  imageBreakpoints
    .map(
      (breakpoint) =>
        `${src}${getQueryParameterSeparator(
          src,
        )}w=${breakpoint}&h=${breakpoint} ${breakpoint}w`,
    )
    .join(', ')

;('...w=1600&h1600 1600w, ...w=800&h800 800w')

export function FeatureImage({
  image,
  className,
  square = false,
  sizes = '',
  usePlaceholderBg = false,
}: {
  className?: string
  square?: boolean
  sizes?: string
  usePlaceholderBg?: boolean
  image: FeatureImageType
}) {
  const srcSet = square ? getSquareSrcSet(image.src) : getSrcSet(image.src)
  const src = square
    ? `${image.src}${getQueryParameterSeparator(image.src)}w=350&h=350`
    : `${image.src}${getQueryParameterSeparator(image.src)}w=350`

  return (
    <img
      loading="lazy"
      className={className}
      srcSet={srcSet}
      width={image.width}
      height={image.height}
      sizes={sizes}
      style={{
        backgroundColor: usePlaceholderBg ? image.bgColor : 'transparent',
      }}
      src={src}
      alt={''}
    />
  )
}
